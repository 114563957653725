import React from "react";
var Response = function (_a) {
    var code = _a.code, message = _a.message;
    return code === 200 ? (React.createElement("div", { className: "thanks" },
        React.createElement("h2", null, "I receive your message"),
        React.createElement("p", null, "Will get back to you shortly."))) : (React.createElement("div", { className: "error" },
        React.createElement("h2", null, "Error"),
        React.createElement("p", null, message)));
};
export default Response;
