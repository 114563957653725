import React from "react";
import Splash from "./Splash";
import Intro from "./Intro";
import Projects from "./Projects";
import Blogs from "./Blogs";
import Contact from "./Contact";
import ErrorBoundary from "../ErrorBoundary";
var Main = function () { return (React.createElement("main", null,
    React.createElement(ErrorBoundary, null,
        React.createElement(Splash, null),
        React.createElement(Intro, null),
        React.createElement(Projects, null),
        React.createElement(Blogs, null),
        React.createElement(Contact, null)))); };
export default Main;
