import React from "react";
import { Link } from "react-router-dom";
import { CONTROL, POST_TYPE } from "../../_constants";
;
var Controller = function (props) {
    var location = props.location, loaded = props.loaded, data = props.data, metaData = props.metaData, setMetaData = props.setMetaData, portraits = props.portraits;
    var currentslide = metaData.currentslide, muted = metaData.muted;
    var currentIndex = -1 + currentslide;
    if (!location.state)
        return null;
    var format = location.state.format;
    if (!loaded)
        return null;
    var totalslide = data.length;
    var handleCurrentSlideLocation = function (event) {
        var type = event.currentTarget.getAttribute("data-control");
        if (type === CONTROL.PREV) {
            handlePrev();
        }
        else {
            handleNext();
        }
    };
    var handleNext = function () {
        var nextSlide = currentslide + 1;
        if (currentslide < totalslide) {
            setMetaData({
                previouslide: currentslide,
                currentslide: nextSlide
            });
        }
    };
    var handlePrev = function () {
        var nextSlide = currentslide - 1;
        if (currentslide > 1) {
            setMetaData({
                previouslide: currentslide,
                currentslide: nextSlide
            });
        }
    };
    return (React.createElement("div", { className: "controller", "data-video": format == POST_TYPE.VIDEO ? "" : null, "data-single": format == POST_TYPE.STANDARD || totalslide < 2 ? "" : null },
        React.createElement("span", { "data-control": CONTROL.CLOSE },
            React.createElement(Link, { to: {
                    pathname: "/",
                    state: {}
                } }, CONTROL.CLOSE)),
        React.createElement("span", { className: "currentslide" }, currentslide),
        React.createElement("span", { className: "divider" }, " / "),
        React.createElement("span", { className: "totalslide" }, totalslide),
        React.createElement("span", { className: "postFormat" }, format),
        React.createElement("span", { "data-control": CONTROL.PREV, onClick: handleCurrentSlideLocation }, CONTROL.PREV),
        React.createElement("span", { "data-control": CONTROL.NEXT, onClick: handleCurrentSlideLocation }, CONTROL.NEXT),
        format == POST_TYPE.VIDEO && (React.createElement("span", { className: "mute", "data-control": CONTROL.MUTE }, muted ? "unmute" : "mute")),
        React.createElement("span", { className: "fullscreen", "data-control": CONTROL.FULLSCREEN }, CONTROL.FULLSCREEN),
        portraits[currentIndex] && React.createElement("span", { className: "scroll" }, "scroll")));
};
export default Controller;
