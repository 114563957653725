import React from "react";
import { Link } from "react-router-dom";
var ProjectLink = function (_a) {
    var url = _a.url, project = _a.project;
    var linkURL = new URL(url);
    return (React.createElement(Link, { to: {
            pathname: linkURL.pathname,
            state: project
        } },
        React.createElement("svg", { x: "0px", y: "0px", viewBox: "0 0 40 40" },
            React.createElement("line", { x1: "25.3", y1: "20", x2: "14.7", y2: "20" }),
            React.createElement("line", { x1: "20", y1: "14.7", x2: "20", y2: "25.3" }),
            React.createElement("circle", { fill: "none", cx: "20", cy: "20", r: "12" })),
        React.createElement("span", { className: "hero smler" }, "Learn more")));
};
export default ProjectLink;
