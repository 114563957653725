import * as React from "react";
var Intro = function () { return (React.createElement("section", { id: "about" },
    React.createElement("div", { className: "slider noslide" },
        React.createElement("div", { className: "slides" },
            React.createElement("div", { className: "slide" },
                React.createElement("div", { className: "wrapper" },
                    React.createElement("h1", null, "Who is this guy"),
                    React.createElement("p", null, "Having a huge passion in designing and breathing creative stuff, Matheus romanticizes a world filled with beauty."),
                    React.createElement("p", null,
                        React.createElement("span", null,
                            React.createElement("a", { href: "#", target: "_blank" }, "resume")),
                        React.createElement("span", null,
                            React.createElement("a", { href: "https://www.linkedin.com/in/mathiouchio", target: "_blank", rel: "noopener noreferrer" }, "linkedin")),
                        React.createElement("span", null,
                            React.createElement("a", { href: "https://github.com/mathiouchio", target: "_blank", rel: "noopener noreferrer" }, "github"))))))))); };
export default Intro;
