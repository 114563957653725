import * as React from "react";
import { Link } from "react-router-dom";
var Nav = function () { return (React.createElement("nav", null,
    React.createElement("ul", null,
        React.createElement("li", null,
            React.createElement(Link, { to: {
                    pathname: "/",
                    hash: "#about"
                } },
                React.createElement("span", null, "01"),
                " about")),
        React.createElement("li", null,
            React.createElement(Link, { to: {
                    pathname: "/",
                    hash: "#portfolio"
                } },
                React.createElement("span", null, "02"),
                " work")),
        React.createElement("li", null,
            React.createElement(Link, { to: {
                    pathname: "/",
                    hash: "#posts"
                } },
                React.createElement("span", null, "03"),
                " blog")),
        React.createElement("li", null,
            React.createElement(Link, { to: {
                    pathname: "/",
                    hash: "#contact"
                } },
                React.createElement("span", null, "04"),
                " connect"))))); };
export default Nav;
