import React, { useState } from "react";
import Online from "./online";
import Form from "./form";
import Submit from "./submit";
import Response from "./response";
import { post, sanitize } from "../../_actions";
import { RESPONSE } from "../../_constants";
var Contact = function () {
    var _a = useState([null, null]), errors = _a[0], setError = _a[1];
    var _b = useState({
        email: "",
        message: ""
    }), value = _b[0], setValue = _b[1];
    var _c = useState({ code: null, message: "" }), response = _c[0], setResponse = _c[1];
    var handleChange = function (event) {
        var currentTarget = event.currentTarget;
        if (currentTarget.type == "email") {
            setValue({
                email: currentTarget.value,
                message: value.message
            });
        }
        if (currentTarget.type == "textarea") {
            setValue({
                email: value.email,
                message: sanitize(currentTarget.value)
            });
        }
    };
    var handleValidate = function (event) {
        var currentTarget = event.currentTarget;
        var email_reg = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
        if (currentTarget.type == "email") {
            if (email_reg.test(currentTarget.value.trim())) {
                setError([false, errors[1]]);
                delete currentTarget.dataset.invalid;
            }
            else {
                setError([true, errors[1]]);
                currentTarget.dataset.invalid = "";
            }
        }
        if (currentTarget.type == "textarea") {
            if (currentTarget.value.trim()) {
                setError([errors[0], false]);
                delete currentTarget.dataset.invalid;
            }
            else {
                setError([errors[0], true]);
                currentTarget.dataset.invalid = "";
            }
        }
    };
    var passCheck = function () {
        var pass = true;
        errors.forEach(function (error) {
            if (error === true || error === null)
                pass = false;
        });
        return pass;
    };
    var handleSubmit = function () {
        if (passCheck()) {
            post({
                url: "https://matheus.li/blog/wp-content/themes/matheus/contact.php",
                inputs: value
            }).then(function (res) {
                setResponse({ code: res === RESPONSE.SUCCESS ? 200 : 400, message: res });
            });
        }
    };
    return (React.createElement("section", { id: "contact" },
        React.createElement("div", { className: "slider noslide" },
            React.createElement("div", { className: "slides" },
                React.createElement("div", { className: "slide" },
                    React.createElement("div", { className: "wrapper" },
                        React.createElement(Online, null),
                        response.code ? (React.createElement(Response, { code: response.code, message: response.message })) : (React.createElement(Form, { handleChange: handleChange, handleValidate: handleValidate })),
                        React.createElement(Submit, { handleSubmit: handleSubmit })))))));
};
export default Contact;
