import React from "react";
import { WPLOCAL } from "../../_constants";
var Form = function (_a) {
    var handleValidate = _a.handleValidate, handleChange = _a.handleChange;
    return (React.createElement("div", { className: "copy" },
        React.createElement("form", { id: "contactform", action: WPLOCAL.templateURL + "contact.php", method: "post" },
            React.createElement("div", null,
                React.createElement("label", null, "email"),
                React.createElement("input", { type: "email", name: "email", onChange: function (event) { return handleChange(event); }, onBlur: function (event) { return handleValidate(event); }, placeholder: "type your email", required: true })),
            React.createElement("div", null,
                React.createElement("label", null, "message"),
                React.createElement("textarea", { name: "message", onChange: function (event) { return handleChange(event); }, onBlur: function (event) { return handleValidate(event); }, placeholder: "type message ...", required: true })))));
};
export default Form;
