import React, { useState, useEffect } from "react";
import { danger, get, scrollspy } from "../../_actions";
import { REQUEST } from "../../_constants";
import Link from "./link";
var Projects = function () {
    var _a = useState({ data: [], loaded: false }), data = _a[0], setData = _a[1];
    var handleFetch = function () {
        get(REQUEST.PORTFOLIOS).then(function (res) {
            setData({ data: res, loaded: true });
        });
    };
    useEffect(function () {
        handleFetch();
    }, []);
    useEffect(function () {
        // recalculate on fetch complete
        scrollspy.calcPositions();
    }, [data]);
    return (React.createElement("section", { id: "portfolio", className: "slider noslide" }, !data.loaded ? ("Loading...") : (React.createElement("div", { className: "slider noslide" },
        React.createElement("div", { className: "slides", "data-expanded": true },
            React.createElement("ul", null, data.data.map(function (project) {
                var link = project.link, id = project.id;
                return (React.createElement("li", { className: "slide", key: id },
                    React.createElement("div", { className: "wrapper" },
                        React.createElement("h1", null, project.title.rendered),
                        React.createElement("div", { className: "copy", dangerouslySetInnerHTML: danger(project.content.rendered) }),
                        React.createElement("div", { className: "expand" },
                            React.createElement(Link, { url: link, project: project })))));
            })))))));
};
export default Projects;
