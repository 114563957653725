var _this = this;
import * as React from "react";
import { Link } from "react-router-dom";
import { get, ohSnap, scrollspy } from "../../_actions";
import { REQUEST } from "../../_constants";
var Blog = function () {
    var _a = React.useState([]), data = _a[0], setData = _a[1];
    var _b = React.useState(false), loaded = _b[0], setLoaded = _b[1];
    var slidesContainer = React.createRef();
    var handleFetch = function () {
        get(REQUEST.POSTS).then(function (res) {
            setData(res);
            setLoaded(true);
        });
    };
    var handleHover = function (e) {
        if (e.type == "mouseenter")
            ohSnap(e.currentTarget, 1);
        else
            ohSnap(e.currentTarget, 0);
    };
    var handleExpand = function () {
        if (slidesContainer.current.dataset.expanded) {
            delete slidesContainer.current.dataset.expanded;
        }
        else {
            slidesContainer.current.dataset.expanded = null;
        }
        // recalculate on expansion
        setTimeout(function () {
            scrollspy.calcPositions();
        }, 300);
    };
    React.useEffect(function () {
        handleFetch();
    }, []);
    React.useEffect(function () {
        // recalculate on fetch complete
        scrollspy.calcPositions();
    }, [data]);
    return (React.createElement("section", { id: "posts", className: "slider noslide" }, !loaded ? ("Loading...") : (React.createElement("div", { className: "slides", ref: slidesContainer },
        React.createElement("div", { className: "slide" },
            React.createElement("div", { className: "wrapper" },
                React.createElement("ul", null, data.map(function (blog) {
                    var featured = blog.better_featured_image;
                    var id = blog.id;
                    if (featured) {
                        var thumb = featured.media_details.sizes.thumbnail;
                        var linkURL = new URL(blog.link);
                        // const arrURL = linkURL.pathname.split("/");
                        var postType = "posts";
                        // const postName = arrURL[1];
                        if (thumb)
                            return (React.createElement("li", { key: id },
                                React.createElement("div", { className: "post" },
                                    React.createElement(Link, { to: {
                                            pathname: "/" + postType + linkURL.pathname,
                                            state: blog
                                        } },
                                        React.createElement("div", { className: "thumb", onMouseEnter: handleHover.bind(_this), onMouseLeave: handleHover.bind(_this) },
                                            React.createElement("svg", { x: "0px", y: "0px", viewBox: "0 0 180 200" },
                                                React.createElement("g", { fillRule: "evenodd", clipRule: "evenodd" },
                                                    React.createElement("defs", null,
                                                        React.createElement("polygon", { id: "SVGID_thumb_" + id + "_", points: "57,199,1,72,113,1,163,30,161,113" })),
                                                    React.createElement("clipPath", { id: "SVGID_thumb_a_" + id + "_" },
                                                        React.createElement("use", { xlinkHref: "#SVGID_thumb_" + id + "_", overflow: "visible" })),
                                                    React.createElement("g", { clipPath: "url(#SVGID_thumb_a_" + id + "_)" },
                                                        React.createElement("image", { overflow: "visible", width: "300", height: "300", xlinkHref: thumb.source_url, transform: "matrix(0.6666666666666666,0,0,0.6666666666666666,-10,0)" })))))))));
                    }
                })),
                React.createElement("div", { className: "expand", onClick: handleExpand },
                    React.createElement("a", null,
                        React.createElement("svg", { x: "0px", y: "0px", viewBox: "0 0 40 40" },
                            React.createElement("line", { x1: "25.3", y1: "20", x2: "14.7", y2: "20" }),
                            React.createElement("line", { x1: "20", y1: "14.7", x2: "20", y2: "25.3" }),
                            React.createElement("circle", { fill: "none", cx: "20", cy: "20", r: "12" })),
                        React.createElement("span", { className: "hero smler" }, "Expand")))))))));
};
export default Blog;
