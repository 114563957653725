import * as React from "react";
var Online = function () { return (React.createElement("div", null,
    React.createElement("label", null, "Social"),
    React.createElement("span", null,
        React.createElement("a", { href: "https://www.facebook.com/pages/matheusli/177957308894747", target: "_blank", rel: "noopener noreferrer" }, "facebook")),
    React.createElement("span", null,
        React.createElement("a", { href: "https://instagram.com/mathiouchio/", target: "_blank", rel: "noopener noreferrer" }, "instagram")),
    React.createElement("span", null,
        React.createElement("a", { href: "https://dribbble.com/mathiouchio", target: "_blank", rel: "noopener noreferrer" }, "dribbble")))); };
export default Online;
